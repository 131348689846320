<template>
  <v-dialog v-model="overlay" persistent width="300">
    <v-card dark class="gradient-bg overflow-hidden">
      <v-card-text class="mb-n1 mt-2" align="center" justify="center">
        Make sure that your computer is connected to the Internet.
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-2 mt-2"
        ></v-progress-linear>
        If you have connection problems, Reload the page.
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    overlay: Boolean,
  },
};
</script>
<template>
  <div id="cap">
    <v-app-bar color="theme" app clipped-left dense>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <img src="@/assets/PrezenceNew.png" width="130px" />
      <v-spacer />
      <v-tooltip bottom>
        <!--dialogCreateIssue = true  -->
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            icon
            @click="report_issue()"
            color="black"
            class="mr-5"
          >
            <v-icon>mdi-information</v-icon>
          </v-btn>
        </template>
        <span>Facing Issues Using Prezence ? Click To Report</span>
      </v-tooltip>
      <div align="center" class="mx-5">
        {{ $store.getters.get_useremail }}
      </div>
      <!-- <v-icon color="red" @click="logout()">mdi-power</v-icon> -->
      <v-icon @click="wire = true" color="red">mdi-power</v-icon>
      <logoutDialogView :wire="wire" @close="wire = false" />
    </v-app-bar>
    <v-navigation-drawer
      width="200px"
      color="theme"
      clipped
      v-model="drawer"
      app
      class="pt-1"
    >
      <v-card
        width="100%"
        v-ripple="false"
        class="elevation-0 borderSizeZero"
        :class="route.color == 'primary' ? 'white--text' : ''"
        v-for="(route, idx) in items"
        :key="idx"
        :color="route.color"
        @click="changeRoute(route, idx, 'MAINROUTE')"
      >
        <v-card-actions class="fontSize">
          <v-icon
            v-text="route.icon"
            class="mr-2"
            :class="route.color == 'primary' ? 'white--text' : ''"
          ></v-icon>
          {{ route.title }}
          <v-spacer></v-spacer>
          <v-icon
            small
            v-if="route.has_child_routes == true"
            :class="route.color == 'primary' ? 'white--text' : ''"
            >mdi-chevron-down</v-icon
          >
        </v-card-actions>
        <div v-if="showSubRoutes">
          <v-card
            v-for="(subroute, sidx) in route.items"
            :key="sidx"
            :color="$route.name == subroute.to ? 'light_primary' : ''"
            v-show="current_idx == idx"
            class="elevation-0 borderSizeZero"
            :class="$route.name == subroute.to ? 'black--text' : ''"
            @click="changeRoute(subroute, idx, 'SUBROUTE')"
          >
            <v-card-actions class="fontSize">
              <v-card-text align="left" class="ml-8 pa-0 ma-0">
                <span v-if="subroute.count == undefined">{{
                  subroute.title
                }}</span>
                <span v-else>
                  {{ subroute.title }}
                  <v-badge
                    color="secondary"
                    :class="
                      subroute.title == 'Customers'
                        ? 'ml-4 pa-0 ma-0'
                        : subroute.title == 'Prospects'
                        ? 'ml-6'
                        : subroute.title == 'Activated'
                        ? 'ml-7'
                        : subroute.title == 'Onboarded'
                        ? 'ml-4'
                        : subroute.title == 'Trial'
                        ? 'ml-13'
                        : subroute.title == 'Deactivated'
                        ? 'ml-2'
                        : subroute.title == 'Recent'
                        ? 'ml-10'
                        : ''
                    "
                    :content="
                      subroute.title == 'Customers'
                        ? organisationCount.customer
                        : subroute.title == 'Prospects'
                        ? organisationCount.prospect !== 0
                          ? organisationCount.prospect
                          : '0'
                        : subroute.title == 'Activated'
                        ? organisationCount.Activated !== 0
                          ? organisationCount.Activated
                          : '0'
                        : subroute.title == 'Onboarded'
                        ? organisationCount.OnBoarded !== 0
                          ? organisationCount.OnBoarded
                          : '0'
                        : subroute.title == 'Trial'
                        ? organisationCount.Trial !== 0
                          ? organisationCount.Trial
                          : '0'
                        : subroute.title == 'Deactivated'
                        ? organisationCount.Deactivated !== 0
                          ? organisationCount.Deactivated
                          : '0'
                        : subroute.title == 'Recent'
                        ? organisationCount.Recent !== 0
                          ? organisationCount.Recent
                          : '0'
                        : '0'
                    "
                  >
                  </v-badge>
                </span>
              </v-card-text>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </div>
      </v-card>
    </v-navigation-drawer>
    <!-- <div ref="home">
      <v-main >
        <router-view/>
      </v-main>
    </div> -->

    <OverlayNet :overlay="overlay" />
    <OverlayO :overlayO="overlayO" />
    <ReportDialog
      @close_report_dialog="Reportissue = false"
      :Reportissue="Reportissue"
      :items="items"
    ></ReportDialog>
  </div>
</template>
<script>
import { Auth } from "aws-amplify";
// import * as htmlToImage from "html-to-image";
import { GetCurrentUserDetails } from "@/mixins/getCurrentUserDetails.js";
import OverlayNet from "@/components/OverlayNet.vue";
import logoutDialogView from "../components/Dialogs/logoutDialogView.vue";
import OverlayO from "@/components/OverlayO.vue";
import { API, graphqlOperation } from "aws-amplify";
import { CountOfOrganisation } from "@/graphql/queries.js";
import ReportDialog from "@/components/Dialogs/ReportDialog.vue";
export default {
  components: {
    logoutDialogView,
    OverlayNet,
    OverlayO,
    ReportDialog,
  },
  mixins: [GetCurrentUserDetails],
  data: () => ({
    wire: false,
    Reportissue: false,
    StoreObj: {},
    overlayO: false,
    selectedFilesFromDrop: [],
    dialogCreateIssue: false,
    selectedGroup: 0,
    Count: 0,
    menu: false,
    drawer: true,
    overlay: false,
    model: "",
    items: [
      {
        icon: "mdi-monitor-dashboard",
        title: "Dashboard",
        to: "Dashboard",
        has_child_routes: false,
        active: true,
        color: "transparent",
      },
      {
        icon: "mdi-account-settings",
        title: "Organisations",
        items: [
          {
            title: "Customers",
            to: "Customer",
            count: 0,
          },
          {
            title: "Prospects",
            to: "Prospect",
            count: 0,
          },
          // {
          //   title: "Activated",
          //   to: "Activated",
          //   count: 0,
          // },
          // {
          //   title: "Onboarded",
          //   to: "Onboarded",
          //   count: 0,
          // },
          {
            title: "Trial",
            to: "Trial",
            count: 0,
          },
          {
            title: "Recent",
            to: "RecentView",
            count: 0,
          },

          {
            title: "Deactivated",
            to: "DeActived",
            count: 0,
          },
        ],
        has_child_routes: true,
        active: false,
      },
      {
        icon: "mdi-account-switch",
        title: "App Users",
        items: [
          {
            title: "MyPrezence",
            to: "MyPrezence",
          },
          {
            title: "Prezence",
            to: "Prezence",
          },
        ],
        has_child_routes: true,
        active: false,
      },
      {
        icon: "mdi-google-maps",
        title: "Locations",
        has_child_routes: false,
        to: "LocationOverall",
        active: false,
        color: "",
      },
      {
        icon: "mdi-cash-check",
        title: "Credits",
        has_child_routes: false,
        to: "Credits",
        active: false,
        color: "",
      },
      {
        icon: "mdi-qrcode",
        title: "QRs",
        items: [
          {
            title: "Produced",
            to: "Produced",
          },
          {
            title: "Printed",
            to: "Printed",
          },
          {
            title: "Claimed",
            to: "Claimed",
          },
        ],
        has_child_routes: true,
        active: false,
      },
      {
        icon: "mdi-account-switch",
        title: "Administrator",
        has_child_routes: false,
        to: "Administrator",
        active: false,
        color: "",
      },
      {
        icon: "mdi-file",
        title: "Form Library",
        has_child_routes: false,
        to: "FormLibrary",
        active: false,
        color: "",
      },

      {
        icon: "mdi-format-text-variant-outline",
        title: "Audit Trail",
        items: [
          {
            title: "Audit Trail",
            to: "AuditActions",
          },
          {
            title: "Swipes Log",
            to: "ResolvedSwipes",
          },
        ],
        has_child_routes: true,
        active: false,
      },
      {
        icon: "mdi-bank-transfer",
        title: "Transactions",
        items: [
          {
            title: "Promotional",
            to: "PromoTransactions",
          },
          {
            title: "Razorpay",
            to: "RazorTransactions",
          },
          {
            title: "IAP",
            to: "IosPaidTransactions",
          },
          {
            title: "BMS (Paid)",
            to: "BmsPaidTransactions",
          },
          {
            title: "BMS ",
            to: "BmsPromoTransactions",
          },
          {
            title: "Reports",
            to: "TransactionReport",
          },
        ],
        has_child_routes: true,
        active: false,
      },
      {
        icon: "mdi-currency-brl",
        title: "Referrals",
        has_child_routes: false,
        to: "Referrals",
        active: false,
        color: "",
      },

      {
        icon: "mdi-human-male-female",
        title: "Sales Rep",
        has_child_routes: false,
        to: "SalesRep",
        active: false,
        color: "",
      },
      {
        icon: "mdi-folder-open",
        title: "Cabinet",
        has_child_routes: false,
        to: "CabinetList",
        active: false,
        color: "",
      },
      {
        icon: "mdi-bulletin-board",
        title: "Banner",
        has_child_routes: false,
        to: "BannerList",
        active: false,
        color: "",
      },

      {
        icon: "mdi-cog",
        title: "Credit  Settings",
        has_child_routes: false,
        to: "CreadSettings",
        active: false,
        color: "",
      },
      // {
      //   icon: "mdi-cog",
      //   title: "Credit Settings",
      //   items: [
      //     {
      //       title: "Country Credit",
      //       to: "CreadSettings",
      //     },
      //     {
      //       title: "Scan Credit",
      //       to: "ScanCredit",
      //     },
      //   ],
      //   has_child_routes: true,
      //   active: false,
      // },
      {
        icon: "mdi-rss-box",
        title: "Release",
        has_child_routes: false,
        to: "releaseView",
        active: false,
        color: "",
      },
      // {
      //   icon: "mdi-rss-box",
      //   title: "delete",
      //   has_child_routes: false,
      //   to: "DeletedMember",
      //   active: false,
      //   color: "",
      // },
    ],
    current_idx: null,
    current_sub_idx: null,
    routeItem: {
      current_idx: null,
      current_sub_idx: null,
    },
    organisationCount: 0,
    showSubRoutes: false,
    firstClick: true,
  }),
  watch: {
    "$route.name"(val) {
      for (let i = 0; i < this.items.length; i++) {
        if (this.items[i].to == val) {
          this.current_idx = i;
        }
      }
      this.items[this.current_idx].color = "primary";
      for (let i = 0; i < this.items.length; i++) {
        if (this.current_idx != i) {
          this.items[i].color = "transparent";
        }
      }
      // this.items[!this.current_idx].color = "transparent";
    },
  },
  created() {
    // this.$store.commit("SET_CURRENT_ROUTE", 0);
    this.GetCurrentUserDetails();
    this.checkInternet();
  },
  mounted() {
    this.get_organisation_count();
    this.updateNavigationValueMethod();
    if (
      this.$store.getters.get_current_route.current_idx != null &&
      this.$store.getters.get_current_route.current_idx != undefined
    ) {
      this.items[this.$store.getters.get_current_route.current_idx].color =
        "primary";
      this.current_idx = this.$store.getters.get_current_route.current_idx;
      if (
        this.items[this.$store.getters.get_current_route.current_idx]
          .has_child_routes == true
      ) {
        this.items[this.$store.getters.get_current_route.current_idx].items[
          this.$store.getters.get_current_route.current_sub_idx
        ].color = "light_primary";
      }
    } else {
      this.$store.commit("SET_CURRENT_ROUTE", 0);
      this.$router.push("Dashboard");
      this.items[0].color = "primary";
      this.current_idx = 0;
    }
  },
  methods: {
    report_issue() {
      this.Reportissue = true;
    },
    updateNavigationValueMethod() {
      let previous_route = this.$store.getters.get_previous_route;
      if (previous_route && previous_route != "") {
        switch (previous_route) {
          case "UserChannels":
            this.selectedItem = 0;
            break;
          case "SystemChannels":
            this.selectedItem = 1;
            break;
          case "RaydeoUsers":
            console.log("check coming inside raydeo users");
            this.selectedItem = 2;
            break;
          case "BMSUsers":
            this.selectedItem = 3;
            break;
          case "Settings":
            this.selectedItem = 4;
            break;
        }
      }

      this.$forceUpdate();
    },
    // htmlToImageMethod() {
    //   this.overlayO = true;
    //   this.$forceUpdate();
    //   this.selectedFilesFromDrop = [];
    //   var self = this;
    //   setTimeout(() => {
    //     htmlToImage.toBlob(this.$refs.home).then(function (blob) {
    //       // console.log(blob);
    //       blob.name = `ScreenShot${new Date().getTime()}.png`;
    //       self.selectedFilesFromDrop.push(blob);
    //       let object = {
    //         selectedFilesFromDrop: self.selectedFilesFromDrop,
    //         routeName: self.$route.name,
    //       };
    //       self.$store.commit("SET_ISSUE_DETAILS", object);
    //       self.$store.commit("SET_PREVIOUS_ROUTE", self.$route.name);
    //       self.$router.push("ReportIssue");
    //     });
    //     self.overlayO = false;
    //   },2000);
    // },
    dialogCreateIssueEmit() {
      this.dialogCreateIssue = false;
      this.selectedFilesFromDrop = [];
    },
    async get_organisation_count() {
      this.overlay = true;
      try {
        let result = await API.graphql(
          graphqlOperation(CountOfOrganisation, {
            input: {},
          })
        );
        this.organisationCount = JSON.parse(result.data.CountOfOrganisation);
        // console.log("organisationCount", this.organisationCount.prospect);
        this.$forceUpdate();
        this.overlay = false;
      } catch (err) {
        console.log("error", err);
        this.overlay = false;
      }
    },
    checkInternet() {
      this.network = setInterval(() => {
        this.checknetwork();
      }, 10);
    },
    checknetwork() {
      var ifConnected = window.navigator.onLine;
      if (!ifConnected) {
        this.overlay = true;
      } else {
        this.overlay = false;
      }
    },
    changeRoute(route, idx, Action) {
      if (route.has_child_routes == true) {
        if (this.firstClick == true) {
          this.showSubRoutes = true;
          this.firstClick = false;
        } else if (this.current_idx == idx) {
          this.showSubRoutes = false;
          this.firstClick = true;
        }
      } else {
        this.showSubRoutes = false;
        this.firstClick = true;
      }
      if (Action == "MAINROUTE") {
        this.current_idx = null;
        this.routeItem.current_idx = idx;
        this.items[idx].color = "primary";
        for (let i = 0; i < this.items.length; i++) {
          if (i != idx) {
            this.items[i].color = "transparent";
          }
        }
        if (this.$route.name != route.to && route.has_child_routes == false) {
          this.$router.push(route.to);
        } else {
          this.current_idx = idx;
        }
      } else if (Action == "SUBROUTE") {
        this.current_sub_idx = idx;
        this.routeItem.current_sub_idx = idx;
        this.$route.name != route.to ? this.$router.push(route.to) : "";
      }
      this.$store.commit("SET_CURRENT_ROUTE", this.routeItem);
    },
    logout() {
      Auth.signOut();
      this.$router.push("/");
      this.$store.commit("SET_CURRENT_ROUTE", {});
    },
  },
};
</script>

<style></style>

import {
    API,
    graphqlOperation
} from "aws-amplify";
import {
    GetUserDetails
} from "@/graphql/queries.js";

export const GetCurrentUserDetails = {
    data: () => ({
        GetCurrentUserObject: {},
    }),
    methods: {
        async GetCurrentUserDetails() {
            try {
                this.MembersItems = [];
                let result = await API.graphql(
                    graphqlOperation(GetUserDetails, {
                        input: {
                            user_email_id: this.$store.getters.get_useremail,
                        },
                    })
                );
                this.GetCurrentUserObject = JSON.parse(result.data.GetUserDetails).data.items
                this.$store.commit('set_user_details', this.GetCurrentUserObject)
                // console.log(this.$store.getters.get_user_details[0].user_email_id,'get_user_details');
            } catch (err) {
                console.log("error", err);
            }
        },
    }
}